<template>
  <div class="flex w-full relative" ref="container">
    <div class="carousel-container p-2">
      <div
        :style="{ transform: 'translateX(' + translateX + 'px)' }"
        class="w-full flex gap-4"
      >
        <slot name="carouselItems" />
      </div>
    </div>
    <Card
      class="absolute p-3 ml-3 flex justify-center rounded-full my-4"
      style="border-radius: 100%"
      v-if="currentIndex > 0"
    >
      <button @click="prev" class="w-full flex">
        <icon icon-name="chevronLeft" size="xsm" />
      </button>
    </Card>
    <Card
      class="absolute p-3 right-10 top-6 flex justify-center rounded-full"
      style="border-radius: 100%"
      v-if="min < itemsLength"
    >
      <button @click="next" class="w-full flex">
        <icon icon-name="chevronRight" size="xsm" />
      </button>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  name: "Carousel",
  components: {
    Card,
  },
  data() {
    return {
      currentIndex: 0,
        max: 0,
        min: 0,
      enableMore: false,
    };
  },
  props: {
    itemWidth: {
      type: Number,
      default: 300,
    },
    itemsLength: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    translateX() {
      return -this.currentIndex * this.itemWidth;
    },
  },
  methods: {
    prev() {
      this.currentIndex =
        (this.currentIndex - 1 + this.itemsLength) % this.itemsLength;
      this.max = this.min - this.currentIndex;
    },
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.itemsLength;
      this.max = this.currentIndex + this.min;
    },
  },
  mounted() {
    const containerWidth = this.$refs.container.offsetWidth;
      const totalItemsWidth = (this.itemWidth * this.itemsLength) + 20;
      if (containerWidth < totalItemsWidth) {
          this.min = Math.round(containerWidth / this.itemWidth) - 1;
      }
      else {
        this.min = this.itemsLength
      }
  },
};
</script>

<style>
.carousel-container {
  display: flex;
  overflow: hidden;
}

.carousel-item {
  flex: 0 0 auto;
  transition: transform 0.5s ease;
}
</style>
